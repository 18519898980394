import React, { useState, useMemo, useEffect } from 'react';
// import axios from 'axios';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { IconCaret } from './icons/IconCaret.jsx';
import { IconCheck } from './icons/IconCheck.jsx';

const getUniqueItemsByProperties = (items, propKey) =>
  items.filter((value, index, self) =>
    index === self.findIndex((t) => (
      t[propKey] === value[propKey]
    ))
  );

export default function ClothList({
  itemKey,
  items,
  limitSelectProductModelNumbers,
  selected,
  onSelect,
  isSelectJacket,
  isSelectVest,
  removeItem,
  onAddToCart,
  onUpdateQuantity,
  onUpdateSize
}) {
  const [categoryOpenStatus, setCategoryOpenStatus] = useState({});
  const [inputSizeItems, setInputSizeItems] = useState({});

  useEffect(() => {
    const productItems = getGroupedByCategoryProductItems(items, itemKey);
    let results = {};
    productItems.forEach((category) => {
      category.children.forEach((ch) => {
        results = {
          ...results,
          [ch.ean]: ch.sizeChildren.find((sizeCh) => sizeCh.size.toUpperCase() === 'L')
            ? 'L'
            : ch.sizeChildren[0].size
        }
      })
    })
    setInputSizeItems(results);
  }, [items, itemKey]);

  function updateSizeHandler(e, ch) {
    setInputSizeItems({
      ...inputSizeItems,
      [ch.ean]: e.target.value
    });
    onUpdateSize({ item: ch, size: e.target.value })
  }

  function getCartUrl (size, item) {
    const index = item.sizeChildren.findIndex((child) => child.size === size);
    if (index < 0) {
      return '';
    }
    return item.sizeChildren[index].cartUrl || '';
  }

  function sortArrayOfGradings(array) {
    function parseGradingOrder(grading) {
      let order;
      if (grading.includes('s'))
          order = -1;
      else if (grading.includes('m'))
          order = 0;
      else if (grading.includes('l'))
          order = 1;
      const n = Number(grading.match(/\d+(?!X)/))
      const numXes = grading.match(/x*/)[0].length
      const mul = n ? n : numXes + 1
      return order * mul;
    }
    return array.slice().sort((a, b) => {
      if (!isNaN(a) && !isNaN(b)) {
        return a - b;
      } else if (!isNaN(a) && isNaN(b)) {
        return -1
      } else if (isNaN(a) && !isNaN(b)) {
        return 1
      } else {
        // if both is not a Number = isNaN(a) && isNaN(b)
        let aOrder = parseGradingOrder(a.toLowerCase());
        let bOrder = parseGradingOrder(b.toLowerCase());
        return aOrder - bOrder;
      }
    });
  }
  function getGroupedByCategoryProductItems(items, itemKey) {
    const categoryItems = [
      ...items
        .reduce((reducer, obj) => {
          const key = obj[itemKey];
          const item = reducer.get(key) ?
            {
              ...reducer.get(key),
              children: [
                ...reducer.get(key).children,
                obj,
              ],
            } :
            {
              [itemKey]: key,
              size: obj.size,
              children: [obj],
            };
          return reducer.set(key, item);
        }, new Map()).values(),
    ];
    const productItems = categoryItems.map((item) => {
      const groupedByModelNameAndColor = [
        ...item.children
          .reduce((reducer, obj) => {
            const key = `${obj.modelNumber}${obj.materialNumber}${obj.colorCode}`;
            const item = reducer.get(key) ?
              {
                ...reducer.get(key),
                sizeChildren: [
                  ...reducer.get(key).sizeChildren,
                  obj,
                ],
              } :
              {
                ...obj,
                sizeChildren: [obj],
              };
            return reducer.set(key, item);
          }, new Map()).values(),
      ]
      return {
        ...item,
        children: groupedByModelNameAndColor.map((groupItem) => {
          return {
            ...groupItem,
            sizeChildren: getUniqueItemsByProperties(groupItem.sizeChildren, 'identification')
          }
        })
      }
    });
    return productItems
  }

  const groupedByCategoryItems = useMemo(() => {
    if (limitSelectProductModelNumbers && limitSelectProductModelNumbers.length) {
      const filteredItems = items.slice().filter((item) => limitSelectProductModelNumbers.includes(item.modelNumber.toString()));
      return getGroupedByCategoryProductItems(filteredItems, itemKey)
    } else {
      return getGroupedByCategoryProductItems(items, itemKey)
    }
  },[items, itemKey, limitSelectProductModelNumbers]);

  const groupedByCategoryDisabledItems = useMemo(() => {
    if (limitSelectProductModelNumbers && limitSelectProductModelNumbers.length) {
      const filteredItems = items.slice().filter((item) => !limitSelectProductModelNumbers.includes(item.modelNumber.toString()));
      return getGroupedByCategoryProductItems(filteredItems, itemKey)
    } else {
      return []
    }
  },[items, itemKey, limitSelectProductModelNumbers]);

  // function handleOnAddToCart(size, item) {
  //   const cartUrl = getCartUrl(size, item);
  //   if (cartUrl) {
  //     axios.get(cartUrl);
  //   } else {
  //     onAddToCart(size, item);
  //   }
  // }

  function CategoryList() {
    return groupedByCategoryItems.slice().map((item, index) =>
      <div
        key={'categoryContainer' + item.productCategory + index}
        className={
          "md:mb-1 py-[20px] md:py-2 px-3.5 md:px-0 md:border-0" +
          (index < groupedByCategoryItems.length - 1 ? " border-b border-black" : "")
        }
      >
        <button
          className="block w-full py-1 pr-2 text-sm font-bold text-black flex items-center justify-between"
          onClick={() => {
            setCategoryOpenStatus({
              ...categoryOpenStatus,
              [item.productCategory]: categoryOpenStatus[item.productCategory] === undefined
                ? false
                : !categoryOpenStatus[item.productCategory]
            })
          }}
        >
          <span>{ item.productCategory } ({ item.children.length })</span>
          <div className={categoryOpenStatus[item.productCategory] ? "transform rotate-180" : ""}>
            <IconCaret />
          </div>
        </button>
        {/* <div className="hidden md:block w-full py-1 pr-2 text-[12px] font-bold text-black flex items-center justify-between">
          <p className='font-flama text-sm'>{ item.productCategory } ({ item.children.length })</p>
        </div> */}

        <ul
          key={'categoryGroup' + item.productCategory + index}
          className={
            "grid grid-cols-1 xl:grid-cols-2 gap-4 mt-4 md:mt-2 mb-4"
            + (categoryOpenStatus[item.productCategory] === undefined || categoryOpenStatus[item.productCategory] === true ? ' block' : ' hidden')
          }
        >
          {
          item.children.map((ch, chIndex) =>
              <li
                key={'children' + index + chIndex}
                className="grid grid-cols-[120px_1fr] gap-3 md:gap-4 h-[200px] md:h-[148px] bg-theme-gray-2 rounded-[7px] overflow-hidden"
              >
                <div
                  className={
                    "relative w-full h-full md:h-[148px] bg-[#E1E1E1] cursor-pointer"
                    + (selected && selected.ean === ch.ean ? ' pointer-events-none' : '')
                  }
                  onClick={() => onSelect(ch)}
                >
                  <LazyLoadImage
                    src={ch.thumbnail}
                    alt={`thumbnail ${ch.modelName} ${ch.materialName} ${ch.colorName}`}
                    className={
                      "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 transition-all duration-300 ease-in-out w-auto h-[135px] object-contain"
                      + ( isSelectJacket
                        ? (selected.identification === ch.identification ? ' opacity-40' : ' opacity-100 hover:scale-110')
                        : (selected && selected.identification === ch.identification ? ' opacity-40' : ' opacity-100 hover:scale-110')
                      )
                    }
                  />
                  {
                    isSelectJacket
                      ? selected.identification === ch.identification
                        ? <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white">
                          Wearing
                        </div>
                        : <></>
                      : selected && selected.identification === ch.identification
                        ? <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white">
                          Wearing
                        </div>
                        : <></>
                  }
                </div>
                <div className="flex flex-col justify-between h-full md:h-[148px] py-2 pr-3.5">
                  <div className="flex justify-between">
                    <div>
                      <p className="font-flama uppercase">
                        { ch.modelName }
                      </p>
                      <p className="text-sm font-flama">
                        { ch.materialName }
                      </p>
                      <p className="text-sm font-flama">
                        { ch.colorName }
                      </p>
                    </div>
                    <p className="min-w-[70px] text-right">
                      8.00 €
                    </p>
                  </div>
                  <div className="w-full flex flex-col md:hidden">
                    <div className="w-full flex items-end justify-end mb-1.5">
                      <div
                        className={
                          "relative flex items-center justify-center w-[81px] h-[34px]"
                          + " text-[12px] text-black uppercase bg-white rounded-[7px]"
                          + " border border-black overflow-hidden hover:bg-black/10"
                        }
                      >
                        <select
                          defaultValue={inputSizeItems[ch.ean]}
                          className="w-full h-full text-center rounded-[7px]"
                          onChange={(e) => updateSizeHandler(e, ch)}
                        >
                          {
                            sortArrayOfGradings(ch.sizeChildren.map(sizeCh => sizeCh.size))
                              .map((sizeText, sizeTextIndex) =>
                                <option key={`sizeItem-${index}${chIndex}${sizeTextIndex}`}>{ sizeText }</option>
                            )
                          }
                        </select>
                      </div>
                    </div>
                    <div className='flex gap-1 justify-between'>
                      {
                        (isSelectJacket || isSelectVest)
                          ? selected.identification === ch.identification
                            ? <button
                              className={
                                "flex items-center justify-center min-w-[64px] h-[34px] px-2"
                                + " text-[12px] text-black uppercase bg-white rounded-[7px]"
                                + " border border-black hover:bg-black/10"
                              }
                              onClick={removeItem}
                            >
                              Remove
                            </button>
                            : <button
                              className={
                                "flex items-center justify-center min-w-[64px] h-[34px] px-2"
                                + " text-[12px] text-black uppercase bg-white rounded-[7px]"
                                + " border border-black hover:bg-black/10"
                              }
                              onClick={() => onSelect(ch)}
                            >
                              <span>Try on</span>
                            </button>
                          : <button
                            className={
                              "flex items-center justify-center min-w-[64px] h-[34px] px-2"
                              + " text-[12px] text-black uppercase bg-white rounded-[7px]"
                              + " border border-black hover:bg-black/10"
                              + (selected && selected.identification === ch.identification ? ' pointer-events-none' : '')
                            }
                            onClick={() => onSelect(ch)}
                          >
                            {
                              selected && selected.identification === ch.identification
                                ? <IconCheck />
                                : <span>Try on</span>
                            }
                          </button>
                      }
                      {
                        (getCartUrl(inputSizeItems[ch.ean], ch))
                        ?
                        <a
                          className={
                            "flex items-center justify-center min-w-[72px] h-[34px] px-3"
                            + " text-white text-[12px] uppercase bg-theme-black rounded-[7px]"
                            + " border border-black hover:bg-black/70 hover:border-black/70"
                          }
                          href={getCartUrl(inputSizeItems[ch.ean], ch)}
                        >
                          hinzufügen
                        </a>
                        :
                        <button
                          className={
                            "flex items-center justify-center min-w-[72px] h-[34px] px-3"
                            + " text-white text-[12px] uppercase bg-theme-black rounded-[7px]"
                            + " border border-black hover:bg-black/70 hover:border-black/70"
                          }
                          onClick={() => onAddToCart(inputSizeItems[ch.ean], ch)}
                        >
                          hinzufügen
                        </button>
                      }
                    </div>
                  </div>
                  <div className="hidden md:flex items-end justify-end">
                    {
                      (isSelectJacket || isSelectVest)
                        ? selected.identification === ch.identification
                          ? <button
                            className={
                              "flex items-center justify-center min-w-[64px] h-[34px] px-2 ml-0 mr-auto"
                              + " text-[12px] text-black uppercase bg-white rounded-[7px]"
                              + " border border-black hover:bg-black/10"
                            }
                            onClick={removeItem}
                          >
                            Remove
                          </button>
                          : <button
                            className={
                              "flex items-center justify-center min-w-[64px] h-[34px] px-2 ml-0 mr-auto"
                              + " text-[12px] text-black uppercase bg-white rounded-[7px]"
                              + " border border-black hover:bg-black/10"
                            }
                            onClick={() => onSelect(ch)}
                          >
                            <span>Try on</span>
                          </button>
                        : <button
                          className={
                            "flex items-center justify-center min-w-[64px] h-[34px] px-2 ml-0 mr-auto"
                            + " text-[12px] text-black uppercase bg-white rounded-[7px]"
                            + " border border-black hover:bg-black/10"
                            + (selected && selected.identification === ch.identification ? ' pointer-events-none' : '')
                          }
                          onClick={() => onSelect(ch)}
                        >
                          {
                            selected && selected.identification === ch.identification
                              ? <IconCheck />
                              : <span>Try on</span>
                          }
                        </button>
                    }
                    <div className='flex gap-2 items-end justify-end'>
                      <div
                        className={
                          "relative flex items-center justify-center w-[64px] 2xl:w-[81px] h-[34px]"
                          + " text-[12px] text-black uppercase bg-white rounded-[7px]"
                          + " border border-black overflow-hidden hover:bg-black/10"
                        }
                      >
                        <select
                          defaultValue={inputSizeItems[ch.ean]}
                          className="w-full h-full text-center rounded-[7px]"
                          onChange={(e) => updateSizeHandler(e, ch)}
                        >
                          {
                            sortArrayOfGradings(ch.sizeChildren.map(sizeCh => sizeCh.size))
                              .map((sizeText, sizeTextIndex) =>
                                <option key={`sizeItem-${index}${chIndex}${sizeTextIndex}`}>{ sizeText }</option>
                            )
                          }
                        </select>
                      </div>
                      {
                        (getCartUrl(inputSizeItems[ch.ean], ch))
                        ?
                        <a
                          className={
                            "flex items-center justify-center min-w-[72px] h-[34px] px-3"
                            + " text-white text-[12px] uppercase bg-theme-black rounded-[7px]"
                            + " border border-black hover:bg-black/70 hover:border-black/70"
                          }
                          href={getCartUrl(inputSizeItems[ch.ean], ch)}
                        >
                          hinzufügen
                        </a>
                        :
                        <button
                          className={
                            "flex items-center justify-center min-w-[72px] h-[34px] px-3"
                            + " text-white text-[12px] uppercase bg-theme-black rounded-[7px]"
                            + " border border-black hover:bg-black/70 hover:border-black/70"
                          }
                          onClick={() => onAddToCart(inputSizeItems[ch.ean], ch)}
                        >
                          hinzufügen
                        </button>
                      }
                    </div>
                  </div>
                </div>
              </li>
            )
          }
        </ul>
      </div>
    );
  }

  function DisabledCategoryList() {
    return groupedByCategoryDisabledItems.slice().map((item, index) =>
      <div
        key={'disableCategoryContainer' + item.productCategory + index}
        className={
          "md:mb-1 py-[20px] md:py-2 px-3.5 md:px-0 md:border-0" +
          (index < groupedByCategoryDisabledItems.length - 1 ? " border-b border-black" : "")
        }
      >
        <button
          className="block w-full py-1 pr-2 text-[12px] md:text-sm font-bold text-black flex items-center justify-between"
          onClick={() => {
            setCategoryOpenStatus({
              ...categoryOpenStatus,
              [item.productCategory]: categoryOpenStatus[item.productCategory] === undefined
                ? false
                : !categoryOpenStatus[item.productCategory]
            })
          }}
        >
          <span>{ item.productCategory } ({ item.children.length })</span>
          <div className={categoryOpenStatus[item.productCategory] ? "transform rotate-180" : ""}>
            <IconCaret />
          </div>
        </button>
        {/* <div className="hidden md:block w-full py-1 pr-2 text-[12px] font-bold text-black flex items-center justify-between">
          <p className='font-flama text-sm'>{ item.productCategory } ({ item.children.length })</p>
        </div> */}

        <ul
          key={'categoryGroup' + item.productCategory + index}
          className={
            "grid grid-cols-1 xl:grid-cols-2 gap-4 mt-4 md:mt-2 mb-4"
            + (categoryOpenStatus[item.productCategory] === undefined || categoryOpenStatus[item.productCategory] === true ? ' block' : ' hidden')
          }
        >
          {
          item.children.map((ch, chIndex) =>
              <li
                key={'children' + index + chIndex}
                className="grid grid-cols-[120px_1fr] gap-3 md:gap-4 h-[200px] md:h-[148px] bg-theme-gray-2 rounded-[7px] overflow-hidden"
              >
                <div
                  className={
                    "relative w-full h-full md:h-[148px] bg-[#E1E1E1] cursor-pointer"
                    + (selected && selected.ean === ch.ean ? ' pointer-events-none' : '')
                  }
                  onClick={() => onSelect(ch, true)}
                >
                  <LazyLoadImage
                    src={ch.thumbnail}
                    alt={`thumbnail ${ch.modelName} ${ch.materialName} ${ch.colorName}`}
                    className={
                      "absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 transition-all duration-300 ease-in-out w-auto h-[135px] object-contain"
                      + (selected && selected.identification === ch.identification ? ' opacity-40' : ' opacity-100 hover:scale-110')
                    }
                  />
                  {
                    selected && selected.identification === ch.identification
                      ? <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white">
                        Wearing
                      </div>
                      : <></>
                  }
                </div>
                <div className="flex flex-col justify-between h-full md:h-[148px] py-2 pr-3.5">
                  <div className="flex justify-between">
                    <div>
                      <p className="font-flama uppercase">
                        { ch.modelName }
                      </p>
                      <p className="text-sm font-flama">
                        { ch.materialName }
                      </p>
                      <p className="text-sm font-flama">
                        { ch.colorName }
                      </p>
                    </div>
                    <p className="min-w-[70px] text-right">
                      8.00 €
                    </p>
                  </div>
                  <div className="w-full flex flex-col md:hidden">
                    <div className="w-full flex items-end justify-end mb-1.5">
                      <div
                        className={
                          "relative flex items-center justify-center w-[81px] h-[34px]"
                          + " text-[12px] text-black uppercase bg-white rounded-[7px]"
                          + " border border-black overflow-hidden hover:bg-black/10"
                        }
                      >
                        <select
                          defaultValue={inputSizeItems[ch.ean]}
                          className="w-full h-full text-center rounded-[7px]"
                          onChange={(e) => updateSizeHandler(e, ch)}
                        >
                          {
                            sortArrayOfGradings(ch.sizeChildren.map(sizeCh => sizeCh.size))
                              .map((sizeText, sizeTextIndex) =>
                                <option key={`disabledSizeItem-${index}${chIndex}${sizeTextIndex}`}>{ sizeText }</option>
                            )
                          }
                        </select>
                      </div>
                    </div>
                    <div className='flex gap-1 justify-between'>
                      {
                        (isSelectJacket || isSelectVest)
                          ? selected.identification === ch.identification
                            ? <button
                              className={
                                "flex items-center justify-center min-w-[64px] h-[34px] px-2"
                                + " text-[12px] text-black uppercase bg-white rounded-[7px]"
                                + " border border-black hover:bg-black/10"
                              }
                              onClick={removeItem}
                            >
                              Remove
                            </button>
                            : <button
                              className={
                                "flex items-center justify-center min-w-[64px] h-[34px] px-2"
                                + " text-[12px] text-black uppercase bg-white rounded-[7px]"
                                + " border border-black hover:bg-black/10"
                              }
                              onClick={() => onSelect(ch, true)}
                            >
                              <span>Try on</span>
                            </button>
                          : <button
                            className={
                              "flex items-center justify-center min-w-[64px] h-[34px] px-2"
                              + " text-[12px] text-black uppercase bg-white rounded-[7px]"
                              + " border border-black hover:bg-black/10"
                              + (selected && selected.identification === ch.identification ? ' pointer-events-none' : '')
                            }
                            onClick={() => onSelect(ch, true)}
                          >
                            {
                              selected && selected.identification === ch.identification
                                ? <IconCheck />
                                : <span>Try on</span>
                            }
                          </button>
                      }
                      {
                        (getCartUrl(inputSizeItems[ch.ean], ch))
                        ?
                        <a
                          className={
                            "flex items-center justify-center min-w-[72px] h-[34px] px-3"
                            + " text-white text-[12px] uppercase bg-theme-black rounded-[7px]"
                            + " border border-black hover:bg-black/70 hover:border-black/70"
                          }
                          href={getCartUrl(inputSizeItems[ch.ean], ch)}
                        >
                          hinzufügen
                        </a>
                        :
                        <button
                          className={
                            "flex items-center justify-center min-w-[72px] h-[34px] px-3"
                            + " text-white text-[12px] uppercase bg-theme-black rounded-[7px]"
                            + " border border-black hover:bg-black/70 hover:border-black/70"
                          }
                          onClick={() => onAddToCart(inputSizeItems[ch.ean], ch)}
                        >
                          hinzufügen
                        </button>
                      }
                    </div>
                  </div>
                  <div className="hidden md:flex items-end justify-end">
                    {
                      (isSelectJacket || isSelectVest)
                        ? selected.identification === ch.identification
                          ? <button
                            className={
                              "flex items-center justify-center min-w-[64px] h-[34px] px-2 ml-0 mr-auto"
                              + " text-[12px] text-black uppercase bg-white rounded-[7px]"
                              + " border border-black hover:bg-black/10"
                            }
                            onClick={removeItem}
                          >
                            Remove
                          </button>
                          : <button
                            className={
                              "flex items-center justify-center min-w-[64px] h-[34px] px-2 ml-0 mr-auto"
                              + " text-[12px] text-black uppercase bg-white rounded-[7px]"
                              + " border border-black hover:bg-black/10"
                            }
                            onClick={() => onSelect(ch, true)}
                          >
                            <span>Try on</span>
                          </button>
                        : <button
                          className={
                            "flex items-center justify-center min-w-[64px] h-[34px] px-2 ml-0 mr-auto"
                            + " text-[12px] text-black uppercase bg-white rounded-[7px]"
                            + " border border-black hover:bg-black/10"
                            + (selected && selected.identification === ch.identification ? ' pointer-events-none' : '')
                          }
                          onClick={() => onSelect(ch, true)}
                        >
                          {
                            selected && selected.identification === ch.identification
                              ? <IconCheck />
                              : <span>Try on</span>
                          }
                        </button>
                    }
                    <div className='flex gap-2 items-end justify-end'>
                      <div
                        className={
                          "relative flex items-center justify-center w-[64px] 2xl:w-[81px] h-[34px]"
                          + " text-[12px] text-black uppercase bg-white rounded-[7px]"
                          + " border border-black overflow-hidden hover:bg-black/10"
                        }
                      >
                        <select
                          defaultValue={inputSizeItems[ch.ean]}
                          className="w-full h-full text-center rounded-[7px]"
                          onChange={(e) => updateSizeHandler(e, ch)}
                        >
                          {
                            sortArrayOfGradings(ch.sizeChildren.map(sizeCh => sizeCh.size))
                              .map((sizeText, sizeTextIndex) =>
                                <option key={`disabledSizeItem-${index}${chIndex}${sizeTextIndex}`}>{ sizeText }</option>
                            )
                          }
                        </select>
                      </div>
                      {
                        (getCartUrl(inputSizeItems[ch.ean], ch))
                        ?
                        <a
                          className={
                            "flex items-center justify-center min-w-[72px] h-[34px] px-3"
                            + " text-white text-[12px] uppercase bg-theme-black rounded-[7px]"
                            + " border border-black hover:bg-black/70 hover:border-black/70"
                          }
                          href={getCartUrl(inputSizeItems[ch.ean], ch)}
                        >
                          hinzufügen
                        </a>
                        :
                        <button
                          className={
                            "flex items-center justify-center min-w-[72px] h-[34px] px-3"
                            + " text-white text-[12px] uppercase bg-theme-black rounded-[7px]"
                            + " border border-black hover:bg-black/70 hover:border-black/70"
                          }
                          onClick={() => onAddToCart(inputSizeItems[ch.ean], ch)}
                        >
                          hinzufügen
                        </button>
                      }
                    </div>
                  </div>
                </div>
              </li>
            )
          }
        </ul>
      </div>
    );
  }

  return <>
    {
      groupedByCategoryDisabledItems.length
        ? <>
          <CategoryList />
          <div className="pt-10 border-t">
            <p className="text-lg font-bold">Note: Temporary Unmatch Products - You can still select product below but the preview will be reset to the selected product. *</p>
            <DisabledCategoryList />
          </div>
        </>
        : <CategoryList />
    }
  </>
}
